<template>
  <div class="h100">
    <div class="cardWrap">
      <div
        class="card"
        v-for="(item, index) in products"
        :key="index"
        @click="clickProduct(item)"
      >
        <div
          :class="[
            productCompanyGroupName(item),
            { showBrandFooter: item.parentId },
            'imgWrap',
          ]"
        >
          <div v-if="item.addons" class="strip">
            <span>โปรโมชั่น</span>
          </div>
          <img
            v-if="item.productPhotos"
            :src="
              item.productPhotos.length > 0
                ? item.productPhotos[0].url
                : $placeholderImg
            "
            loading="lazy"
          />
          <img v-else :src="$placeholderImg" />
          <OverlayOutofStock :product="item" />
        </div>
        <div class="detailWrap">
          <div>{{ item.name }}</div>
          <div class="priceWrap">
            <span class="price">
              <span v-if="calStep(item)"
                >{{ calStep(item).min.toLocaleString() }} ~
                {{ calStep(item).max.toLocaleString() }}</span
              >
              <span v-else>{{ item.salePrice.toLocaleString() }}</span>
            </span>
            <span class="unitPrice">บาท</span>
            <span v-if="item.price !== item.salePrice" class="fromPrice"
              >{{ item.price }} <span class="unitPrice">บาท</span></span
            >
            <span class="unit"> / {{ item.unit }}</span>
          </div>
        </div>
        <div v-if="$isBuyer && !isAddOrder" class="actionContain">
          <div class="actionWrap" v-if="item.product_status !== 'สินค้าหมด'">
            <div
              class="buyBtn"
              @click.stop="clickAddToCart({ product: item })"
              :data-tag="'buyBtn:' + item.id"
            >
              เพิ่มสินค้า
            </div>
          </div>
        </div>
        <div v-if="isAddOrder" class="actionContain">
          <div class="actionWrap">
            <div class="buyBtn" @click.stop="addToOrder(item)">
              เพิ่มลงออเดอร์
            </div>
          </div>
        </div>
      </div>
    </div>
    <scroll-loader
      :loader-method="() => $emit('scrollEnd')"
      :loader-disable="fetching || endList || error"
      :loader-size="0"
      class="reset"
    >
    </scroll-loader>
    <ProductListLoader v-if="fetching" />
    <DefaultDisplayStage :error="error" :nodata="nodata" />
    <div v-if="products.length > 0" style="height: 100px"></div>
    <AlertAddCart ref="alertAddCart" />
  </div>
</template>

<script>
import { call } from "vuex-pathify";
import ProductListLoader from "@/components/loader/ProductListLoader";
import DefaultDisplayStage from "@/components/DefaultDisplayStage";
import AlertAddCart from "@/components/AlertAddCart";
import { calStep } from "@/utils/product";
import OverlayOutofStock from "./components/OverlayOutofStock";

export default {
  props: {
    products: Array,
    fetching: Boolean,
    endList: Boolean,
    error: Boolean,
    nodata: Boolean,
    mode: String,
    isAddOrder: Boolean,
    clickItem: {
      default: true,
    },
    useModal: {
      default: true,
    },
  },
  components: {
    ProductListLoader,
    DefaultDisplayStage,
    AlertAddCart,
    OverlayOutofStock,
  },
  methods: {
    calStep: calStep,
    ...call("cart/*"),
    ...call("showProduct/*"),
    clickProduct(item) {
      if (this.isAddOrder) {
        return;
      }

      if (!this.clickItem) {
        return;
      }

      if (!this.useModal) {
        this.$router.push(`/products/${item.id}`);
        return;
      }

      this.showProduct(item);
    },
    addToOrder(item) {
      this.$emit("clickItem", item);
      return;
    },
    clickAddToCart(data) {
      this.addCart(data);
      this.$refs.alertAddCart.tick();
    },
  },
  mounted() {
    // document.body.classList.remove("modal-open");
  },
};
</script>
<style lang="scss" scoped>
@import "~@/styles/productCardList.scss";
</style>