<template>
  <transition name="fade">
    <div v-if="show" class="alertAddCart">
      <div class="contain">
        <img class="checkMarkIcon" src="@/assets/img/checked.svg" alt="" />
        <div>เพิ่มสินค้าแล้ว</div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      timer: null,
    };
  },
  methods: {
    tick() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.show = true;
      this.timer = setTimeout(() => {
        this.show = false;
      }, 600);
    },
  },
};
</script>
<style lang="scss" scoped>
.alertAddCart {
  // position: absolute;
  // top: 0;
  // left: 0;
  // width: 100%;
  // height: 100%;
  // z-index: 5;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  z-index: 100;
  position: fixed;
}

.checkMarkIcon {
  width: 40px;
  margin-bottom: 15px;
}

.contain {
  z-index: 100;
  // position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 160px;
  height: 110px;
  background: rgba(0, 0, 0, 0.651);
  border-radius: 14px;
  text-align: center;
  color: #fff;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>