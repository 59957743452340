<template>
  <div v-if="product.product_status === 'สินค้าหมด'" class="root">
    สินค้าหมด
  </div>
</template>

<script>
export default {
  props: { product: Object },
};
</script>

<style lang="scss" scoped>
.root {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 18px;
  z-index: 1;
}
</style>